/* ==============================================
	サイト内全般で使用するJavaScript 

	SpMenuToggle.......スマホメニュー開閉
	Tab................タブ切り替え
	ToPageTop..........トップに戻るボタン
	PageSmoothScroll...ページ内スムーススクロール
	==============================================*/

// #SpMenuToggle
// スマホメニュー開閉
(function () {
	var btnMenu = $('.js-buttonMenu');
	var toggleElement = $('.js-headerToggleElement');
	var classOpened = 'is-opened';
	var ariaLabels = 'メニューを開く';
	var linkBtn = $('.header-nav-item a');

	// スマホメニュー開閉処理
	function SpMenuToggle(t) {
		t.closest('.js-header').toggleClass(classOpened);
		if (t.attr('aria-label') == ariaLabels) {
			// --- 開く時の処理
			btnMenu.attr('aria-expanded', true);
			toggleElement.attr('aria-hidden', false);
		} else {
			// --- 閉じる時の処理
			btnMenu.attr('aria-expanded', false);
			toggleElement.attr('aria-hidden', true);
		}
	}

	// スマホメニューのリンクをクリックした時、スマホメニューを閉じる
	linkBtn.on('click', function (e) {
		SpMenuToggle($(this));
	})

	// メニューボタンを押してスマホメニューを開閉させる
	btnMenu.on('click', function () {
		SpMenuToggle($(this));
	});
})();

// #Tab
// タブ切り替え
class Tab {
	constructor() {
		this.tabSwitch = document.querySelectorAll('[role="tab"]')
	}

	init() {
		this.attachTab();
	}

	attachTab() {
		for (const _switch of this.tabSwitch) {
			_switch.addEventListener('click', (e) => {
				let id;
				if (e.target.nodeName === 'SPAN') {
					id = e.target.parentNode.getAttribute('aria-controls');
				} else if (e.target.nodeName === 'BUTTON') {
					id = e.target.getAttribute('aria-controls');
				}
				let thisArea = e.target.closest('.js-tabarea');
				this.hideTabPanel(e, id, thisArea);
				this.showTabPanel(e, id, thisArea);
			})
		}
	}

	showTabPanel(e, id, area) {
		let targetPanel = area.querySelector('#' + id);
		if (e.target.nodeName === 'SPAN') {
			e.target.parentNode.setAttribute('aria-selected', 'true');
		} else if (e.target.nodeName === 'BUTTON') {
			e.target.setAttribute('aria-selected', 'true');
		}
		targetPanel.setAttribute('aria-hidden', 'false');
	}

	hideTabPanel(e, id, area) {
		let activeTab = area.querySelectorAll('[aria-selected=true');
		let activePanel = area.querySelectorAll('[aria-hidden=false');
		activeTab.forEach(tab => {
			tab.setAttribute('aria-selected', 'fasle');
		});
		activePanel.forEach(panel => {
			panel.setAttribute('aria-hidden', 'true');
		})
	}
}
var tab = new Tab;
tab.init();

// #ToPageTop
class ToPageTop {
	constructor() {
		this.buttonToTop = document.querySelectorAll('.js-buttonToTop');
	}

	init() {
		this.scrolltoTop();
	}

	scrolltoTop() {
		this.buttonToTop;
		for (const button of this.buttonToTop) {
			button.addEventListener('click', (e) => {
				window.scrollTo({
					top: 0,
					behavior: 'smooth'
				});
			});
		};
	};
};
var toPageTop = new ToPageTop;
toPageTop.init();

// #PageSmoothScroll
(function () {
	var headerHeight = $('.js-header').outerHeight();
	$('a[href^="#"]').click(function () {
		var href = $(this).attr("href");
		var target = $(href);
		var position = target.offset().top - headerHeight;
		$('body,html').stop().animate({ scrollTop: position }, 300);
		return false;
	});
})();